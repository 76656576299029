<template>
  <section>
    <div class="main" v-for="(item, index) in tasks" :key="index" id="itemList">
      <div class="date">{{ item.missionDate }}</div>
      <div
        class="items"
        v-for="val in item.missionList"
        :key="val.missionSubId"
        :top="val.isLastRunning || false"
      >
        <!--创建时间-->
        <div class="createTime">
          <img
            v-if="[1].includes(val.missionStatus)"
            src="@/assets/VehicleTask/ing.png"
            class="icon"
            alt=""
          />
          <img
            v-if="[2, 11].includes(val.missionStatus)"
            src="@/assets/VehicleTask/toBeImplemented.png"
            class="icon"
            alt=""
          />
          <img
            v-if="[3].includes(val.missionStatus)"
            src="@/assets/VehicleTask/done.png"
            class="icon"
            alt=""
          />
          <img
            v-if="[4, 6].includes(val.missionStatus)"
            src="@/assets/VehicleTask/fail.png"
            class="icon"
            alt=""
          />
          <img
            v-if="[5, 11].includes(val.missionStatus)"
            src="@/assets/VehicleTask/pending.png"
            class="icon"
            alt=""
          />
          <img
            v-if="[12].includes(val.missionStatus)"
            src="@/assets/VehicleTask/unexecuted.png"
            class="icon"
            alt=""
          />
          <div class="time">{{ val.executeTime }}</div>
        </div>
        <!--任务卡片-->
        <div class="taskBox">
          <div class="card" @click="toDetail(val)">
            <div class="subTit" v-if="val.missionTypeMsg">
              <div class="missionName">
                <span
                  >{{ val.missionTypeMsg }}{{ val.missionEnumMsg ? '丨' : ''
                  }}{{ val.missionEnumMsg }}</span
                >
                <img
                  v-if="val.cageMission"
                  class="caged"
                  src="@/assets/VehicleTask/caged.png"
                  alt=""
                />
              </div>

              <!--状态为执行中，已完成，失败，已取消 && 子任务非 上电，下电-->
              <img
                v-if="
                  [1, 3, 4, 6].includes(val.missionStatus) &&
                  !['上电', '下电'].includes(val.missionEnumMsg)
                "
                src="@/assets/VehicleTask/toDetail.png"
                alt=""
              />
            </div>
            <div class="tit">
              <span class="sub">{{ val.missionSubContent }}</span>
              <span>{{ val.missionContent }}</span>
            </div>

            <div class="flexBox">
              <!--结束时间-->
              <div class="txt1">
                <span v-if="val.missionStatus !== 1">{{ val.finishTime }}</span>
                <span v-else>{{ val.executeTime }}</span>
                <span>{{ missionStatusEnum[val.missionStatus].text ?? val.missionStatusMsg }}</span>
                <!-- <span>{{ val.missionStatusMsg === '执行中' ? '发起' : val.missionStatusMsg }}</span> -->
              </div>
              <!--距离-->
              <div
                class="txt1"
                v-if="
                  ![1, 2, 12].includes(val.missionStatus) &&
                  !['上电', '下电'].includes(val.missionEnumMsg)
                "
              >
                <img src="@/assets/VehicleTask/distance.png" alt="" />
                <span>{{ val.drivingDistance }}</span>
              </div>
              <!--用时-->
              <div class="txt1" v-if="![1, 2, 12].includes(val.missionStatus)">
                <img src="@/assets/VehicleTask/duration.png" alt="" />
                <span>{{ val.drivingDuration }}</span>
              </div>
              <div class="inProgress" v-if="val.missionStatus === 1">执行中</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TaskRecordNode',
  data() {
    return {
      missionStatusEnum: {
        1: { text: '发起', color: '#436EFF' },
        2: { text: '待执行', color: '#8A8CA4' },
        3: { text: '完成', color: '#00C483' },
        4: { text: '取消', color: '#8A8CA4' },
        5: { text: '挂起', color: '#FFB800 ' },
        6: { text: '失败', color: '#ED3651' },
        12: { text: '未执行', color: '#8A8CA4' },
      },
    };
  },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
  },

  mounted: function () {
    // missionStatus 状态字典
    // RUNNING(1, "执行中"),
    // READYTORUN(2, "待执行"),
    // FINISHED(3, "已完成"),
    // CANCELED(4, "已取消"),
    // SUSPEND(5, "挂起"),
    // FAILED(6, "失败"),
    // FAILED_RETRY(7, "失败-重试中"),
    // WAITING_SEND(10, "等待派单中"),
    // WAITING(11, "等待"),
    // NOT_DONE(12, "未执行");
  },

  methods: {
    toDetail(item) {
      if (
        [1, 3, 4, 6].includes(item.missionStatus) &&
        !['上电', '下电'].includes(item.missionEnumMsg)
      ) {
        this.$router.push({
          path: '/missionDetail',
          query: {
            vin: this.vin,
            missionSubId: item.missionSubId,
            missionTypeMsg: item.missionTypeMsg,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  //justify-content: center;
  //align-items: center;

  .date {
    font-size: 0.28rem;
    color: #9798ab;
    font-weight: 600;
    height: 0.9rem;
    line-height: 0.9rem;
    align-self: center;
  }

  .items {
    margin: 0.16rem 0.24rem;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 0;
    }

    .createTime {
      display: flex;

      .icon {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.12rem;
      }

      .time {
        font-size: 0.32rem;
        color: #000000;
        letter-spacing: 0;
        font-weight: 600;

        //&.done{
        //  color: #9798AB;
        //}
      }
    }

    .taskBox {
      border-left: 0.04rem dashed rgba(203, 206, 219, 1);
      padding-left: 0.315rem;
      padding-bottom: 0.26rem;
      margin-top: 0.14rem;
      margin-left: 0.2rem;

      .card {
        background: #ffffff;
        box-shadow: 0 2px 10px 0 rgb(60 64 70 / 4%);
        border-radius: 0.12rem;
        padding: 0.2rem 0.2rem 0.2rem 0.24rem;

        .subTit {
          font-size: 0.28rem;
          color: #000000;
          letter-spacing: 0;
          font-weight: 400;
          margin-bottom: 0.12rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .missionName {
            display: flex;
            align-items: center;
            .caged {
              width: 0.42rem;
              height: 0.42rem;
              margin-left: 0.16rem;
            }
          }

          & > img {
            width: 0.4rem;
          }
        }

        .tit {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-size: 0.32rem;
          color: #000000;
          letter-spacing: 0;
          font-weight: 600;
          margin-bottom: 0.12rem;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          // white-space: nowrap;
          -webkit-line-clamp: 2;

          .sub {
            font-size: 0.28rem;
            color: #000000;
            font-weight: 400;
            margin-right: 0.08rem;
          }
        }

        .flexBox {
          display: flex;
          align-items: center;
          font-size: 0.28rem;
          color: #9798ab;
          letter-spacing: 0;
          font-weight: 400;

          .txt1 {
            margin-right: 0.25rem;
            display: flex;
            align-items: flex-end;

            &:last-child {
              margin-right: 0;
            }

            & > img {
              width: 0.4rem;
            }
          }

          .inProgress {
            color: #436eff;
            letter-spacing: 0;
            font-weight: 600;
            margin-left: auto;
          }
        }
      }
    }
  }
}
</style>
