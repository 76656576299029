var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',_vm._l((_vm.tasks),function(item,index){return _c('div',{key:index,staticClass:"main",attrs:{"id":"itemList"}},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.missionDate))]),_vm._l((item.missionList),function(val){return _c('div',{key:val.missionSubId,staticClass:"items",attrs:{"top":val.isLastRunning || false}},[_c('div',{staticClass:"createTime"},[([1].includes(val.missionStatus))?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/VehicleTask/ing.png"),"alt":""}}):_vm._e(),([2, 11].includes(val.missionStatus))?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/VehicleTask/toBeImplemented.png"),"alt":""}}):_vm._e(),([3].includes(val.missionStatus))?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/VehicleTask/done.png"),"alt":""}}):_vm._e(),([4, 6].includes(val.missionStatus))?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/VehicleTask/fail.png"),"alt":""}}):_vm._e(),([5, 11].includes(val.missionStatus))?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/VehicleTask/pending.png"),"alt":""}}):_vm._e(),([12].includes(val.missionStatus))?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/VehicleTask/unexecuted.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"time"},[_vm._v(_vm._s(val.executeTime))])]),_c('div',{staticClass:"taskBox"},[_c('div',{staticClass:"card",on:{"click":function($event){return _vm.toDetail(val)}}},[(val.missionTypeMsg)?_c('div',{staticClass:"subTit"},[_c('div',{staticClass:"missionName"},[_c('span',[_vm._v(_vm._s(val.missionTypeMsg)+_vm._s(val.missionEnumMsg ? '丨' : '')+_vm._s(val.missionEnumMsg))]),(val.cageMission)?_c('img',{staticClass:"caged",attrs:{"src":require("@/assets/VehicleTask/caged.png"),"alt":""}}):_vm._e()]),(
                [1, 3, 4, 6].includes(val.missionStatus) &&
                !['上电', '下电'].includes(val.missionEnumMsg)
              )?_c('img',{attrs:{"src":require("@/assets/VehicleTask/toDetail.png"),"alt":""}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"tit"},[_c('span',{staticClass:"sub"},[_vm._v(_vm._s(val.missionSubContent))]),_c('span',[_vm._v(_vm._s(val.missionContent))])]),_c('div',{staticClass:"flexBox"},[_c('div',{staticClass:"txt1"},[(val.missionStatus !== 1)?_c('span',[_vm._v(_vm._s(val.finishTime))]):_c('span',[_vm._v(_vm._s(val.executeTime))]),_c('span',[_vm._v(_vm._s(_vm.missionStatusEnum[val.missionStatus].text ?? val.missionStatusMsg))])]),(
                ![1, 2, 12].includes(val.missionStatus) &&
                !['上电', '下电'].includes(val.missionEnumMsg)
              )?_c('div',{staticClass:"txt1"},[_c('img',{attrs:{"src":require("@/assets/VehicleTask/distance.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(val.drivingDistance))])]):_vm._e(),(![1, 2, 12].includes(val.missionStatus))?_c('div',{staticClass:"txt1"},[_c('img',{attrs:{"src":require("@/assets/VehicleTask/duration.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(val.drivingDuration))])]):_vm._e(),(val.missionStatus === 1)?_c('div',{staticClass:"inProgress"},[_vm._v("执行中")]):_vm._e()])])])])})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }