<template>
  <div id="contentBox">
    <div class="main" ref="main" :style="{ paddingTop: `${barHeight + 40}px` }">
      <!--头部-->
      <BackHeader title="任务记录" @onBack="goBack" />
      <!-- 任务组件 -->
      <TaskRecordNode :tasks.sync="tasks" v-if="tasks.length > 0 && loaded" />
      <!--没有数据的缺省情况-->
      <div v-else-if="tasks.length <= 0 && loaded" class="noData">
        <img src="@/assets/VehicleTask/noData.png" alt="" />
        <p class="txt">暂无数据</p>
      </div>
      <img
        src="@/assets/VehicleTask/backToTop.png"
        alt=""
        class="backToTop"
        v-show="showBackTopBtn"
        @click="backTop"
      />
    </div>
  </div>
</template>

<script>
import TaskRecordNode from './components/TaskRecordNode';
import { getRecentMissionDayListByVin } from '@/api/api';
import Message from 'vue-m-message';
import BackHeader from './components/BackHeader';

export default {
  name: 'TaskRecord',
  components: {
    TaskRecordNode,
    BackHeader,
  },

  props: {},

  data() {
    return {
      tasks: [],
      polling: null,
      vin: '',
      loaded: false,
      showBackTopBtn: false,
      screenHeight: document.documentElement.clientHeight,
      // documentHeight:document.documentElement.scrollHeight,
      scrollTop: 0,
      timer: null,
      barHeight: parseInt(sessionStorage.getItem('_appBarHeight') ?? 0),
    };
  },

  computed: {},

  mounted: function () {
    // 本地调试代码
    // this.vin = this.$route.query.vin ?? 'LHTBW2B2XPYBEA001';
    // this.getTask();
    // this.polling = setInterval(()=>this.getTask(true), 5000);
    if (this.$route.query.vin) {
      this.vin = this.$route.query.vin;
      this.getTask();
      this.polling = setInterval(() => this.getTask(true), 5000);
    } else {
      Message.error('没有找到车辆vin,请重试');
    }

    let contentBox = document.getElementById('contentBox');
    contentBox.addEventListener('scroll', this.windowScroll, true);
    contentBox.addEventListener('touchstart', this.touchstart, true);
    contentBox.addEventListener('touchmove', this.touchmove, true);
  },

  beforeDestroy: function () {
    let contentBox = document.getElementById('contentBox');
    if (contentBox) {
      contentBox.removeEventListener('scroll', this.windowScroll);
      contentBox.removeEventListener('touchstart', this.touchstart);
      contentBox.removeEventListener('touchmove', this.touchmove);
    }
    this.polling && clearInterval(this.polling);
  },

  activated() {
    //滚动到之前的位置
    this.$nextTick(() => {
      document.getElementById('contentBox').scroll({
        left: 0,
        top: this.scrollTop,
      });
    });
  },

  methods: {
    changeLocal() {
      window.location.href = 'http://172.16.5.30:8080/taskRecord';
    },
    touchstart(ev) {
      this.screenY = ev.changedTouches[0].screenY;
    },
    touchmove(ev) {
      //在顶部往上滑
      if (this.screenY < ev.changedTouches[0].screenY && this.scrollTop === 0) {
        ev.preventDefault();
      }
      //在底部往下滑
      else if (
        this.screenY > ev.changedTouches[0].screenY &&
        this.scrollTop + this.screenHeight === this.$refs.main?.clientHeight
      ) {
        ev.preventDefault();
      }
    },
    goBack() {
      window.location.href = 'neolix://interaction';
    },
    getTask(today = false) {
      if (!today) this.$loadingCircle.start(); //loading
      const _this = this;

      getRecentMissionDayListByVin({
        vin: this.vin,
        onlyToday: today, //不传或者false就是7天，传true是当天
      })
        .then((res) => {
          if (res.length <= 0) return;
          //已经请求过，只更新当天的数据
          if (this.tasks.length > 0) {
            //如果是同一天
            if (this.tasks[0].missionDate === res[0].missionDate) {
              this.tasks.splice(0, 1, res[0]);
            }
            //不是同一天则push到数组第一位，并且删掉最后一位
            else {
              this.tasks.splice(0, 0, res[0]);
              this.tasks.splice(-1, 1);
            }
          } else {
            _this.tasks = res;
            _this.loaded = true; //写了两次是因为需要$nextTick
            //1.9.3滚动到相应元素的位置
            _this.$nextTick(() => {
              const ele = Array.from(document.querySelectorAll('.items')).find(
                (item) => item.attributes.top
              );
              ele && _this.elementScrollToTop(ele); //给指定的元素滚动到页面顶部
            });
          }
        })
        .finally(() => {
          this.loaded = true;
          this.$loadingCircle.end();
        });
    },
    elementScrollToTop(el) {
      const rect = el.getBoundingClientRect();
      const _appBarHeight =
        parseInt(sessionStorage.getItem('_appBarHeight')) ?? 40;
      document.getElementById('contentBox').scroll({
        top: rect.top - _appBarHeight - 48, //48为左上角时间刻度高度
        left: 0,
        behavior: 'smooth',
      });
    },
    windowScroll(e) {
      this.scrollTop = e.target.scrollTop;
      this.timer !== null && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showBackTopBtn = e.target.scrollTop >= this.screenHeight;
        clearTimeout(this.timer);
      }, 300);
    },
    backTop() {
      this.$nextTick(() => {
        document.getElementById('contentBox').scroll({
          top: 0,
          left: 0,
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
//覆盖头部组件的文字样式
:deep(.header .navBar div) {
  font-size: 0.3rem;
}
#contentBox {
  height: 100%;
  overflow: auto;
}
.main {
  // background: #f6f7f8;
  box-sizing: border-box;
  //min-height: 100%;
  padding-top: 0.79rem;
  transition: 0.5s;
}
.noData {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  padding-top: 3rem;

  img {
    width: 3.2rem;
    height: 3.2rem;
  }
  .txt {
    font-size: 0.32rem;
    color: #000000;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.backToTop {
  position: fixed;
  bottom: 1rem;
  right: 0.12rem;
  z-index: 2;
  width: 1.28rem;
  //transform: rotate(90deg);
  transition: 0.5s;
}
.header {
  width: 100%;
  height: 0.79rem;
  line-height: 0.79rem;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 0.22rem;
  position: fixed;
  top: 0;
  left: 0;

  .back {
    width: 0.64rem;
    position: absolute;
    top: 0.07rem;
    left: 0.22rem;
  }
  .tit {
    font-size: 0.3rem;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    margin: auto;
  }
}
</style>
